<template>
  <v-container fluid>
    <v-card class="mb-10 pa-2">
      <div class="header-wrapper d-flex flex-wrap justify-space-between">
        <div class="date-picker">
          <date-picker
            :outlined="true"
            :value="fromDate"
            label="Datum"
            :dateFormatted="fromDateFormatted"
            @changeDate="changeFromDate"
          ></date-picker>
        </div>
        <div class="date-picker">
          <date-picker
            :outlined="true"
            :value="toDate"
            label="t/m"
            :dateFormatted="toDateFormatted"
            @changeDate="changeToDate"
          ></date-picker>
        </div>
        <div class="search-supplier">
          <search-supplier
            :outlined="true"
            :searchString="searchSupplierString"
            :noResults="false"
            @setSearchString="setSearchSupplierString"
            @get-results="getSupplierResults"
            @clear-input="clearSupplier"
          ></search-supplier>
        </div>
        <div>
          <v-select
            clearable
            v-model="selectedCrateType"
            outlined
            :items="crateTypes"
            label="Type krat"
          ></v-select>
        </div>
        <div>
          <v-checkbox
            label="Alles"
            @change="fetchCratesBalance"
            v-model="allDates"
          ></v-checkbox>
        </div>
        <div class="totalBalance">{{ balanceMessage }} {{ totalBalance }}</div>
        <v-btn color="primary" @click="openCrateModal({ date: new Date() })"
          >Nieuw</v-btn
        >
      </div>
    </v-card>
    <v-row>
      <v-col>
        <v-data-table
          :items="filteredItems"
          :headers="itemHeaders"
          :server-items-length="itemsLength"
          :options.sync="options"
          @update:page="fetchCratesBalance"
          @update:items-per-page="fetchCratesBalance"
        >
          <template v-slot:[`item.date`]="{ item }">
            {{ toDateString(item.date) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon @click="openCrateModal(item)">mdi-pencil</v-icon>
            <v-icon @click="deleteCrateItem(item._id)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <crate-modal
      v-if="crateModal"
      :crateModal="crateModal"
      :item="item"
      @insert-crate-item="insertCrateItem"
      @close-crate-modal="closeCrateModal"
    ></crate-modal>
  </v-container>
</template>
<script>
import {
  errorHandler,
  fetchGET,
  fetchPOST,
  successHandler,
  toDateString
} from "../js/functions"
import crateModal from "@/components/crateModal.vue"
import searchSupplier from "@/components/searchSupplier.vue"
import datePicker from "@/components/datePicker.vue"
export default {
  name: "cratesBalance",
  data() {
    return {
      fromDate: new Date(Date.now()).toISOString().split("T")[0],
      toDate: new Date(Date.now()).toISOString().split("T")[0],
      fromDateFormatted: this.formatDate(
        new Date().toISOString().split("T")[0]
      ),
      toDateFormatted: this.formatDate(new Date().toISOString().split("T")[0]),
      allDates: false,
      searchSupplierString: "",
      supplierNumber: null,
      orderNumber: null,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["date"],
        sortDesc: [true]
      },
      totalBalance: 0,
      item: {},
      items: [],
      itemsLength: 0,
      itemHeaders: [
        { text: "Leveranciernr", value: "supplier.supplierNumber" },
        { text: "Naam", value: "supplier.name" },
        { text: "Datum", value: "date" },
        { text: "Ordernummer", value: "orderNumber" },
        { text: "Type krat", value: "crateType" },
        { text: "Inkomend", value: "inbound" },
        { text: "Uitgaand", value: "outbound" },
        { text: "Acties", value: "actions" }
      ],
      crateModal: false,
      crateTypes: ["Kipkrat", "Blokpallet", "Europallet"],
      selectedCrateType: ""
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split("-")
      return `${day}-${month}-${year}`
    },
    changeFromDate(date) {
      this.fromDate = date
      this.fromDateFormatted = this.formatDate(date)
      if (this.fromDate > this.toDate) {
        this.toDate = this.fromDate
        this.toDateFormatted = this.fromDateFormatted
      }
      this.fetchCratesBalance()
    },
    changeToDate(date) {
      this.toDate = date
      this.toDateFormatted = this.formatDate(date)
      if (this.toDate < this.fromDate) {
        this.fromDate = this.toDate
        this.fromDateFormatted = this.toDateFormatted
      }
      this.fetchCratesBalance()
    },
    toDateString(val) {
      return toDateString(val)
    },
    setSearchSupplierString(val) {
      this.searchSupplierString = val
    },
    getSupplierResults(supplier) {
      this.supplierNumber = supplier.supplierNumber
      this.fetchCratesBalance()
    },
    clearSupplier() {
      this.searchSupplierString = ""
      this.supplierNumber = null
      this.fetchCratesBalance()
    },
    async fetchCratesBalance() {
      try {
        let response = await fetchGET("fetchCratesBalance", {
          supplierNumber: this.supplierNumber,
          orderNumber: this.orderNumber,
          fromDate: this.fromDate,
          toDate: this.toDate,
          allDates: this.allDates,
          options: this.options
        })
        console.log(response[0].totalBalance[0])
        this.items = response[0].results ? response[0].results : []
        this.itemsLength = response[0].length.length
          ? response[0].length[0].length
          : 0
        this.totalBalance = response[0].totalBalance[0].totalBalance
      } catch (e) {
        errorHandler(e, "Kan kratten saldo niet ophalen")
      }
    },
    openCrateModal(item) {
      this.item = item
      this.crateModal = true
    },
    closeCrateModal() {
      this.item = {}
      this.crateModal = false
    },
    async insertCrateItem(val) {
      try {
        let event = val._id ? "updateCrateItem" : "insertCrateItem"
        let response = await fetchPOST(event, val)
        if (response.result.n == 0) throw event
        let successMessage =
          event === "updateCrateItem"
            ? "Kratregistratie gewijzigd"
            : "Kratregistratie opgeslagen"
        successHandler(successMessage, 1000)
        if (event === "updateCrateItem") {
          let index = this.items.findIndex(item => item._id === val._id)
          if (index > -1) Object.assign(this.items[index], val)
        } else this.items.push(response.ops[0])
        this.closeCrateModal()
      } catch (e) {
        errorHandler(e, "Kratregistratie niet opgeslagen")
      }
    },
    async deleteCrateItem(_id) {
      try {
        let response = await fetchPOST("deleteCrateItem", { _id: _id })
        if (response.result.n == 0) throw "deleteCrateItem"
        successHandler("Kratregistratie verwijderd", 1000)
        let index = this.items.findIndex(item => item._id === _id)
        if (index > -1) this.items.splice(index, 1)
        this.closeCrateModal()
      } catch (e) {
        errorHandler(e, "Kratregistratie niet verwijderd")
      }
    }
  },
  computed: {
    filteredItems() {
      if (this.selectedCrateType) return this.items.filter(item => item.crateType === this.selectedCrateType)
      return this.items
    },
    balanceMessage() {
      if (this.totalBalance >= 0) return "Te leveren"
      return "Te ontvangen"
    }
  },
  mounted() {
    this.fetchCratesBalance()
  },
  components: { crateModal, searchSupplier, datePicker }
}
</script>
<style scoped>
.header-wrapper > div {
  max-width: 150px;
  margin-right: 5px;
}
.header-wrapper .search-supplier {
  min-width: 250px;
  max-width: 250px;
}
.header-wrapper .totalBalance {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 1.5rem;
  max-width: unset;
}
</style>